<template>
    <div class="product-body main home-main">
        <QsNavbar :title="tag=='fav'?'我的收藏':'浏览记录'"></QsNavbar>
        <div class="q-box">
            <QsProductItem :item="item" v-for="item in list" :key="item.id"></QsProductItem>
        </div>
    </div>
</template>

<script>

import { mapState,mapGetters } from 'vuex'
import QsProductItem from '@components/qs-product-item'

export default {
    data(){
        return{
            tag:"",
            list:[]
        }
    },
    mounted(){
        this.tag = this.$route.query.tag;
        this.onLoad();
    },
    methods:{
        onLoad(){
            this.$ajax.get("client/my/product_home_list?tag="+this.tag).then(res=>{
                this.list = res.data;
            }).catch(e=>{})
        }
    },
    components: {
        QsProductItem
    },
    computed:{
        ...mapState({
            user:state=>state.user
        }),
        ...mapGetters(['isLogin','isSubscribe','isRegister']),
    }    
}
</script>


<style lang="scss" scoped>
.product-list ::v-deep .product-box:last-of-type .product-item {border-bottom:none;}
</style>

